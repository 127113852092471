
.full-page {
    height: 100vh;
}

.App {
    display: grid;
    grid-template-columns: 10% 80% 10%;
    width: 100%;
    flex-direction: row;
}

.page-container {
    background: linear-gradient(90deg, rgb(68, 90, 109), #003853);
}

.App > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column-start: 2;
    grid-column-end: 3;
}

@media screen and (min-width: 320px) {
    .App {
        grid-template-columns: 1% 98% 1%;
    }
}
