@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,500;0,700;0,900;1,900&display=block');

* {
    font-family: "Montserrat";
    -ms-overflow-style: none;
}

body {
  margin: 0;
}

