.home {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.socials {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.8rem;
    left: 0;
    position: fixed;
    height: 100%;
    width: 10%;
    top: 0;
    transition: transform 0.3s cubic-bezier(.04,.71,.62,1.35);
}

.line {
    height: 100%;
    background: rgba(0, 55, 83, 0.5);
    width: 1px;
}

.separator {
    background: rgba(0, 55, 83, 0.5);
    width: 1px;
    height: 0.5em;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

.line.before {
    height: 100%;
}

.line.after {
    height: 100%;
}

.socials .buttons {
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.socials .buttons a {
    color: rgba(0, 55, 83, 0.5);
    text-decoration: none;
    transition: transform 0.3s cubic-bezier(.04,.71,.62,1.35);
}

.socials .buttons a:hover {
    transform: scale(1.2);
}

.svg-inline--fa path {
    fill: rgba(0, 55, 83, 0.5) !important;
}

@media screen and (max-width: 600px) {
    .socials {
        display: none;
    }
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-family: "Montserrat";
    letter-spacing: -1px;
}

.info h1 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 5em;
    margin: 0;
    line-height: 0.8em;
    letter-spacing: -10px;
}

@media screen and (min-width: 0px) {
    .info h1 {
        font-size: 3rem;
        letter-spacing: -5px;
    }
}

@media screen and (min-width: 320px) {
    .info h1 {
        font-size: 3rem;
        letter-spacing: -3px;
    }
    .social {
        width: 0;
    }
    .spacer {
        width: 0;
    }
}

@media (min-width: 500px) {
    .info h1 {
        font-size: 4rem;
        letter-spacing: -5px;
    }
}

@media (min-width: 800px) {
    .info h1 {
        font-size: 5rem;
        letter-spacing: -7px;
    }
}

@media (min-width: 1000px) {
    .info h1 {
        font-size: 7rem;
        letter-spacing: -10px;
    }
}

.info .intro-title {
    text-shadow: none;
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: 2rem;
    font-weight: 100;
    flex: 1;
    letter-spacing: -1px;
    flex-grow: 4;
}

.content .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: auto;
    width: 50%;
}

.buttons .resume-button,
.buttons .contact-button {
    border: 2px solid black;
    text-decoration: none;
    border-radius: 5px;
    padding: 2% 4%;
    font-weight: 300;
    color: rgba(200, 200, 200);
    background-color: black;
    letter-spacing: normal;
    text-align: center;
}

.buttons .resume-button:hover {
    background-color: transparent;
}

.buttons .contact-button:hover {
    background-color: transparent;
}

.info .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 4em;
    font-weight: 900;
    color: rgb(0, 0, 0);
    height: 250px;
    flex-grow: 2;
}

.info .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1 1;
    flex-grow: 5;
}

.info .titles {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: row;
    font-size: 1.5em;
    font-weight: 300;
    justify-content: center;
    letter-spacing: -1px;
    margin: auto;
    text-align: center;
}

.info .titles .title {
    position: relative;
}

.info .titles p:not(:last-child)::after {
    content: "\2193";
    color: black;
    display: inline-block;
    margin-left: 0.5em;
    position: relative;
    bottom: -0.25em;
    transition: transform 0.5s ease, opacity 0.5s ease !important;
}

.info .titles p:not(:first-child)::before {
    content: "";
    margin-right: 0.5em;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.info .titles.scrolled p:not(:last-child)::after {
    content: "/";
    color: black;
    display: inline-block;
    position: relative;
    top: -0.1em;
    transform: rotate(-180deg);
    opacity: 1;
}

.info .titles.scrolled p:not(:first-child)::before {
    content: "/";
    color: black;
    display: inline-block;
    position: relative;
    bottom: -0.1em;
    transform: rotate(0deg);
    opacity: 1;
}

.info .titles p:not(:last-child)::after {
    transform: rotate(0deg);
    opacity: 1;
}

.info .titles p:not(:first-child)::before {
    transform: rotate(-90deg);
    opacity: 0;
}

@media screen and (max-width: 865px) {
    .App {
        grid-template-columns: 5% 90% 5%;
    }
    .home .titles {
        flex-direction: column;
    }
    .info .titles {
        text-align: left !important;
        align-items: flex-start;
    }
    .info .titles p {
        margin: 0;
        margin-top: 10px;
    }
    .info .titles p::after {
        display: none !important;
    }
    .info .titles p::before {
        content: "/ /" !important;
        color: black;
        position: relative;
        display: inline-block;
        margin-right: 0.5em;
        bottom: -0.1em;
    }
    #action-buttons {
        width: 75% !important;
    }
}

@media screen and (max-width: 500px) {
    #action-buttons {
        width: 100% !important;
    }
}

#main-img {
    max-height: 60vh;
}

.title .first {
    margin-right: 2rem;
    cursor: default;
}

.title .last {
    margin-left: 2.2rem;
}

.title .first {
    line-height: 0.8em;
    letter-spacing: -10px;
}

.title .last {
    line-height: 0.8em;
    letter-spacing: -10px;
}

.socials .buttons a:focus,
.buttons .resume-button:focus,
.buttons .contact-button:focus {
    outline: 3px solid black;
    outline-offset: 2px;
}
